<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='importConfirm' :isdeling='isdeling'>
    <!-- <priview-pdf :pdfUrl="pdfUrl"></priview-pdf> -->
    <div class="main-section" id="content" ref="contentDiv" v-loading='loading'></div>
    <div class="bottom_info">
      <span>存储位置：增值税销项电子发票目录/{{year}}年/{{month}}月</span>
      <el-button type="primary" class="download" @click="batchDownLoad" >下载</el-button>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  import PriviewPdf from "@/components/PriviewPdf";
  // import {parseOfdDocument, renderOfd} from "ofd.js";
  import axios from 'axios';
  export default {
    props: {
      title: {
        type: String,
      },
      visible: {
        type: Boolean,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      itemId: {
        type: String,
      }
    },
    components: {
      Dialog,
      PriviewPdf
    },
    data() {
      return {
        // pdfUrl: 'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf',
        pdfUrl: '/static/test.pdf',
        // pdfUrl: '/static/test.ofd',

        // fileUrl : 'https://51shouzu.xyz/ofd/999.ofd',
        fileUrl: 'http://121.37.165.123:30900/uploadeinv202108/53e8596e20d647258629fe82902cefb5.ofd',
        config: {
          top: '10vh',
          width: '46rem',
          type: 'OFD',
          title:'',
          center: true,
          btnTxt: [],
        },
        year: '',
        month: '',
        fileList: [],
        fileData: '',
        isdeling: false,
        tableData: [],
        status:  false,
        message : '请选择上传文件',
        screenWidth :'840',
        loading : false,
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
      },
      importConfirm(){
        this.cancelDialog();
      },
      getOfdDocumentObj(file, screenWidth) {
          let that = this;
          let t = new Date().getTime();
          this.loading = true;
          // parseOfdDocument({
          //   ofd: file,
          //   success(res) {
          //     let t1 = new Date().getTime();
          //     console.log('解析ofd',t1 - t);
          //     that.ofdObj = res[0];
          //     that.pageCount = res[0].pages.length;
          //     const divs = renderOfd(screenWidth, res[0]);
          //     let t2 = new Date().getTime();
          //     console.log('xml转svg', t2 - t1)
          //     that.displayOfdDiv(divs);
          //     let t3 = new Date().getTime();
          //     console.log('svg渲染到页面', t3 - t2);
          //     that.loading = false;
          //   },
          //   fail(error) {
          //     that.loading = false;
          //     that.$alert('OFD打开失败', error, {
          //       confirmButtonText: '确定',
          //       callback: action => {
          //         einvAlert.warning("提示",`action: ${ action }`);
          //       }
          //     });
          //   }
          // });
        },

      displayOfdDiv(divs) {
        let contentDiv = document.getElementById('content');
        contentDiv.innerHTML = '';
        for (const div of divs) {
          contentDiv.appendChild(div)
        }
    },

    // fileChanged() {
    //   this.file = this.$refs.file.files[0];
    //   let ext = this.file.name.replace(/.+\./, "");
    //   if (["ofd"].indexOf(ext) === -1) {
    //     this.$alert('error', '仅支持ofd类型', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //         einvAlert({
    //           type: 'info',
    //           message: `action: ${ action }`
    //         });
    //       }
    //     });
    //     return;
    //   }
    //   if (this.file.size > 100 * 1024 * 1024) {
    //     this.$alert('error', '文件大小需 < 100M', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //         einvAlert({
    //           type: 'info',
    //           message: `action: ${ action }`
    //         });
    //       }
    //     });
    //     return;
    //   }
    //   let that = this;
    //   let reader = new FileReader();
    //   reader.readAsDataURL(this.file);
    //   reader.onload = function (e) {
    //     that.ofdBase64 = e.target.result.split(',')[1];
    //   }
    //   this.getOfdDocumentObj(this.file, this.screenWidth);
    //   this.$refs.file.value = null;
    // },
      show() {
        //let ofdFile = 'http://121.37.165.123:30900/uploadeinv202108/53e8596e20d647258629fe82902cefb5.ofd';
        this.getOfdDocumentObj(this.fileUrl, this.screenWidth);
      },
            // 下载pdf文件
      batchDownLoad(){
        var fileName = this.title
        axios({
          method: 'get',
          url: '/api/einvoice/einv/download',
          responseType: 'arraybuffer',
          params: {
            id:this.itemId,
          },
          headers: {
            'Content-Type': "application/octet-stream;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          // let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf;chartset=UTF-8" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.exportLoading = false
        })
      },
    },
    // mounted(){
    //   this.getOfdDocumentObj(this.fileUrl, this.screenWidth);
    // },
    watch:{  //监听xxx发生改变
      title(val){
        if(val){
          this.config.title = this.title
        }
      },
      screenWidth: {
        immediate:true,
        handler(val){
          this.screenWidth = 840 * document.body.offsetWidth / 1920
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .firstStep {
    margin: 24px 0 12px;
  }
  .upload {
    cursor: pointer;
    color: #4f71ff;
  }
  .main-section{
    min-height: 500px;
  }
  .bottom_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 38px;
    margin: 4px 0 24px;
  }
  .download {
    width: 100px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    // color: #ffffff;
  }
</style>
